import { Modal } from "antd"
import useBearStore from "../../zustand/state"
import download1 from '../../assets/img/download1.png'
import download2 from '../../assets/img/download2.png'
import download3 from '../../assets/img/download3.png'

/**
 * 下载 弹框
 */
const SysModalDownload = () => {

  const { downloadDisplay, setDownloadDisplay } = useBearStore()

  /**
   * 下载软件
   * @param {*} type 
   */
  const onClickDownload = (type) => {
    
  }

  /**
   * 关闭弹框
   */
  const onCancelModal = () => {
    setDownloadDisplay(false)
  }

  return <Modal
    centered
    open={downloadDisplay}
    onCancel={onCancelModal}
    footer={null}
    width={690}
    maskClosable={false}
    destroyOnClose={true}
  >
    <div className="w-full">
      <div className="text-center text-xl font-bold">软件下载</div>
      <div className="flex flex-row justify-between mt-8 pb-2">
        <div className="cursor-pointer" style={{ width: '200px' }} onClick={() => { onClickDownload('mindcms') }}>
          <img className="w-full" src={download1} alt="" />
          <div className="w-full text-center mt-4 text-gray-500">新智思维教学管理平台</div>
        </div>
        <div className="cursor-pointer" style={{ width: '200px' }} onClick={() => { onClickDownload('mindant') }}>
          <img className="w-full" src={download2} alt="" />
          <div className="w-full text-center mt-4 text-gray-500">新智思维学生练习平台</div>
        </div>
        <div className="cursor-pointer" style={{ width: '200px' }} onClick={() => { onClickDownload('mind360') }}>
          <img className="w-full" src={download3} alt="" />
          <div className="w-full text-center mt-4 text-gray-500">新智思维家校互动APP</div>
        </div>
      </div>
    </div>
  </Modal>
}

export default SysModalDownload