import { Input, message, Modal } from "antd"
import useBearStore from "../../zustand/state"
import { reqArticleDel } from "../../util/api"
import { useState } from "react"

/**
 * 删除新闻
 * @param {*} props 
 */
const NewsToolDel = (props) => {

  // 删除ID
  const delIds = props.delIds
  // 
  const { newsDelDisplay, setNewsDelDisplay } = useBearStore()
  // 秘钥
  const [inputVal, setInputVal] = useState('')

  /**
   * 确认
   */
  const onOkModal = () => {
    let param = {}
    param.ids = delIds
    param.pass = inputVal

    if (!param.pass) {
      return
    }

    reqArticleDel(param).then((res) => {
      if (res.res === 1) {
        props.callback && props.callback()
        setNewsDelDisplay(false)
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  /**
   * 关闭弹框
   */
  const onCancelModal = () => {
    setNewsDelDisplay(false)
  }

  /**
   * 秘钥 输入
   * @param {*} e 
   */
  const onChangeInput = (e) => {
    setInputVal(e.target.value)
  }

  return <Modal
    title="删除文章"
    centered
    open={newsDelDisplay}
    onOk={onOkModal}
    onCancel={onCancelModal}
    maskClosable={false}
    destroyOnClose={true}
    cancelText='取消'
    okText='确认'
  >
    <div className="my-8">
      <span>秘钥：</span>
      <Input onChange={onChangeInput} className="w-80" />
    </div>
  </Modal>
}

export default NewsToolDel