
import egox from '../../assets/img/product/egox.png'

/**
 * Thinkband便携式脑电仪
 * @returns 
 */
const ProductEgox = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      <div>
        <img style={{ width: '490px' }} src={egox} alt="" />
      </div>
      <div className='pt-8 pl-8' style={{ width: '645px' }}>
        <div className='text-2xl font-bold'>Egox便携式脑电仪</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>EgoX脑电仪，第六代超便携式脑电采集设备，是新智思维课堂的脑电训练设备之一，适配多种脑电外设，实现多能力的扩展训练。应用领域为多参数情绪测量、注意力测量，已规模用于焦虑、抑郁、睡眠等情绪检测。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>Egox脑电仪突出优势：</div>
      <div className='text-sm mt-4 leading-6'>1.超便携：伸缩式可调脑环形态，适配更多的头形，佩戴时和戴眼镜的方法类似，依人体工程学设计的顺耳廓状干电极，增强信号质量且省去戴耳夹的过程。</div>
      <div className='text-sm mt-4 leading-6'>2.采用超轻柔性FPC非侵入式干电极，柔性硅胶支撑框架，整体重量仅53克，几乎无佩戴感。</div>
      <div className='text-sm mt-4 leading-6'>3.优化算法：高效识别心电、脑电和肌电的干扰信号，能胜任高精度的研究项目。</div>
      <div className='text-sm mt-4 leading-6'>4.高性能：双脑电处理器融合架构，对脑电数据的采集效率提升4倍。</div>
      <div className='text-sm mt-4 leading-6'>5.蓝牙通讯技术，支持Windows、Android、iOS等多个平台。</div>
    </div>
  </div>
}

export default ProductEgox