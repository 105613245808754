
import { Layout } from "antd"
import { useNavigate } from "react-router-dom"
import useBearStore from "../../zustand/state"
import logo2 from '../../assets/img/logo2.png'
import gongan from '../../assets/img/gongan.png'
import consult from '../../assets/img/consult.png'
import gongzhonghao from '../../assets/img/gongzhonghao.png'

const { Footer } = Layout

/**
 * 底部
 * @returns 
 */
const SysFooter = () => {

  const naviget = useNavigate()
  const { setDownloadDisplay } = useBearStore()

  /**
   * 点击 公网安备
   */
  const onClickGongan = () => {
    window.open('https://beian.mps.gov.cn/#/query/webSearch?code=11011502038302', '_blank')
  }

  /**
   * 点击 IP
   */
  const onClickIp = () => {
    window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
  }

  /**
   * 点击 菜单
   */
  const onClickMemu = (key) => {
    if ('download' === key) {
      setDownloadDisplay(true)
    }
    else {
      naviget('/' + key)
    }
  }

  return <Footer className="w-full p-0 overflow-hidden">
    <div className="w-full">
      <div className="relative m-auto w-1200" style={{ height: '200px', color: '#9199A1' }}>
        <div className="absolute left-0 top-4 h-full flex flex-row" >
          <div
            className="cursor-pointer"
            style={{
              width: '210px',
              height: '50px',
              backgroundImage: `url(${logo2})`,
              backgroundSize: '100% 100%',
            }}
          >
          </div>
        </div>

        <div className="absolute left-1/4 top-4 h-full flex flex-row">
          <div className="flex flex-row">
            <div className="mr-12">
              <div className="text-lg leading-10 text-white">相关链接</div>
              <div className="cursor-pointer leading-8 hover:text-white" onClick={() => { onClickMemu('about') }}>关于我们</div>
              <div className="cursor-pointer leading-8 hover:text-white" onClick={() => { onClickMemu('download') }}>软件下载</div>
              <div className="cursor-pointer leading-8 hover:text-white" onClick={() => { onClickMemu('serviceagreement') }}>用户服务协议</div>
              <div className="cursor-pointer leading-8 hover:text-white" onClick={() => { onClickMemu('privacy') }}>隐私政策</div>
            </div>
            <div className="">
              <div className="text-lg leading-10 text-white">联系我们</div>
              <div className="leading-8">电话：400-005-8128</div>
              <div className="leading-8">Email：jm@vipbrain.cn</div>
              <div className="leading-8">地址：北京经济技术开发区科创十二街8号北斗导航产业园2号楼A座415</div>
              <div className="leading-8">
                <a className="hover:text-white" href="https://www.cusoft.com.cn/" target='_blank'>视友科技 官网</a>
                &emsp; &emsp; &emsp; &emsp;
                {/* <a className="hover:text-white" href="https://www.cusoft.com.cn/" target='_blank'>CHBSE 脑科学探索中心</a> */}
              </div>
            </div>
          </div>
        </div>

        <div className="absolute right-0 top-4 h-full flex flex-row">
          <div className="flex flex-row">
            <div className="mr-6">
              <div style={{ width: '120px', height: '120px', backgroundImage: `url(${consult})`, backgroundSize: '100% 100%' }}></div>
              <div className="text-center mt-1">在线咨询</div>
            </div>
            <div className="">
              <div style={{ width: '120px', height: '120px', backgroundImage: `url(${gongzhonghao})`, backgroundSize: '100% 100%' }}></div>
              <div className="text-center mt-1">新智注意力公众号</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full border-t-2 border-gray-600">
      <div className="w-1200 m-auto">
        <div className="flex flex-row justify-center items-center h-12" style={{ color: '#9199A1' }}>
          <div
            className="cursor-pointer"
            style={{
              width: '20px',
              height: '20px',
              backgroundImage: `url(${gongan})`,
              backgroundSize: '100% 100%',
            }}
            onClick={onClickGongan}
          ></div>
          <div className="cursor-pointer mr-1" onClick={onClickGongan}>京公网安备11011502038302 |</div>
          <div className="cursor-pointer mr-8" onClick={onClickIp}>京ICP备18027617号-5</div>
          <div className="" >Copyright 2004-2024 北京新智思维教育科技有限公司 | 北京视友科技出品</div>
        </div>
      </div>
    </div>

  </Footer>
}

export default SysFooter