
import others1 from '../../assets/img/product/others1.jpg'
import others2 from '../../assets/img/product/others2.png'
import others3 from '../../assets/img/product/others3.jpg'

/**
 * 脑机互动体验产品
 * @returns 
 */
const ProductOthers = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      {/* <div>
        <img style={{ width: '490px' }} src={penguin1} alt="" />
      </div> */}
      <div className='w-full pt-8'>
        <div className='text-2xl font-bold'>脑机互动体验产品</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>除了有专业的系统化的脑电训练系统，我们也专门开发了丰富有趣的脑机体验产品。其目的是，通过交互体验在5分钟内快捷地让学生、家长及不同的客户群体认识脑科学，了解大脑认知能力，认可脑电训练的价值</div>
        <div className='text-sm mt-4 leading-6'>这些体验项目经常被用校区招生推广、社区公益活动、校园科普、科技节、大型展览会等场合，所到之处均是体验者爆满。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>互动体验的价值：</div>
      <div className='text-sm mt-4 leading-6'>1.拉进与客户的距离。一试胜千言，能够让客户快速地认可产品及服务。场景：新智注意力合作机构在某社区开展注意力测评体验活动，许多孩子看到脑波闪电侠非常好玩，就嚷着让家长扫码排队。通过体验，家长知道了了什么才是真正的脑电反馈，同时也发现了孩子注意力的优势和弱势。</div>
      <div className='text-sm mt-4 leading-6'>2.与客户保持互动。场景：家长通过体验活动加公司的公众号，不但能了解到很多科学用脑的知识，而且期望到机构训练，提升孩子的情绪能力。</div>
      <div className='text-sm mt-4 leading-6'>3.网红科技。场景：在某大型家博展览会上，展会组办方在会场设立了团体脑波拔河，现场人声鼎沸，观众争相体验打卡，很多人从朋友圈看到之后特意去展览会。</div>
      
      <div className='text-base font-bold mt-4'>产品系列：</div>
      
      <div className='text-base font-bold mt-6'>1、脑波闪电侠</div>
      <div className='text-sm mt-4 leading-6'>脑波闪电侠是基于脑电生物反馈技术研发的互动性很强的大脑技术，包括1V1双人赛、5V5团体赛，支持双人对抗、10人分组对抗、擂台赛模式。</div>
      <div className='text-sm mt-4 leading-6'>客户微信扫码加入比赛，两组参与者头戴脑波仪器，脑波仪测量大脑的情绪状态和专注度参数，通过大脑注意力(意念)控制冰球与火球的能量，大脑注意能力越大，大屏幕上球的力量越大，直至把对方的能量球击退。体验结束，大屏幕立即弹出体验报告，与注意力有关的数值也会推送到体验者的微信上。</div>
      <img className='w-2/3 m-auto mt-8' src={others1} alt='' />
      
      <div className='text-base font-bold mt-6'>2、脑波拔河</div>
      <div className='text-sm mt-4 leading-6'>是基于脑机接口和脑电生物反馈技术的产品，包括1V1双人赛、5V5团体赛，支持双人对抗、10人分组对抗、擂台赛模式。</div>
      <div className='text-sm mt-4 leading-6'>客户微信扫码加入比赛，分辨代表两组小蚂蚁，两组参与者头戴脑波仪器，脑波仪测量大脑的情绪状态和专注度参数，当一方的专注力越高且情绪越稳定，小蚂蚁的能力就越大。体验结束，大屏幕立即弹出体验报告，与注意力有关的数值也会推送到体验者的微信上。</div>
      
      <div className='text-base font-bold mt-6'>3、脑波火车/赛车</div>
      <div className='text-sm mt-4 leading-6'>思达脑波火车也叫意念火车，是一款基于脑机接口和脑电生物反馈技术的智能硬件设备，思达脑波火车通过脑电生物反馈技术进行专注力训练，适用于心理活动室的心理干预应用设备。</div>
      <div className='text-sm mt-4 leading-6'>脑电火车可通过使用者的专注力或放松度指数，控制双轨脑波火车的速度，从而使专注能力及自主调节放松能力在日常生活中得到训练提升，消除焦虑紧张情绪，缓解压力，提升心理调节能力，实现身心健康。体验结束，大屏幕立即弹出体验报告，与注意力有关的数值也会推送到体验者的微信上。</div>
      <img className='w-1/2 m-auto mt-8' src={others2} alt='' />
      <img className='w-1/2 m-auto mt-8' src={others3} alt='' />
    </div>
  </div>
}

export default ProductOthers