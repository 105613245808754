import { useEffect, useState } from 'react'
import star from '../../assets/img/star.png'
import star2 from '../../assets/img/star2.png'
import arrow from '../../assets/img/arrow.png'
import arrow2 from '../../assets/img/arrow2.png'

/**
 * 按钮
 * @param {*} param0 
 */
const SysBtn = ({ btnText, btnType, clickCallback }) => {

  const [iconDisplay, setIconDisplay] = useState('')
  const [starUrl, setStarUrl] = useState('')
  const [arrowUrl, setArrowUrl] = useState('')
  const [textColor, setTextColor] = useState('#ffffff')
  const [bgVal, setBgVal] = useState('')

  useEffect(() => {
    switch (btnType) {
      case 'homeBtn':
      case 'cooperateBtn':
        setStarUrl(star2)
        setArrowUrl(arrow2)
        setTextColor('#5F98FF')
        setBgVal('#FDF4A0')
        setIconDisplay('block')
        break;
      case 'auditionBannerBtn':
        setStarUrl(star)
        setArrowUrl(arrow)
        setTextColor('#ffffff')
        setBgVal('linear-gradient( 270deg, #2AB6FF 0%, #4D8CFF 100%)')
        setIconDisplay('block')
        break;
      default:
        setStarUrl('')
        setArrowUrl('')
        setTextColor('#ffffff')
        setBgVal('linear-gradient( 270deg, #2AB6FF 0%, #4D8CFF 100%)')
        setIconDisplay('none')
        break;
    }
  }, [btnType])

  /**
   * 鼠标移入
   */
  const onMouseOverBtn = () => {
    switch (btnType) {
      case 'homeBtn':
      case 'cooperateBtn':
        setBgVal('#FFE700')
        break;
      case 'auditionBannerBtn':
        setBgVal('#2F60BB')
        break;
      default:
        setBgVal('#2F60BB')
        break;
    }
  }

  /**
   * 鼠标移出
   */
  const onMouseOutBtn = () => {
    switch (btnType) {
      case 'homeBtn':
      case 'cooperateBtn':
        setBgVal('#FDF4A0')
        break;
      case 'auditionBannerBtn':
        setBgVal('linear-gradient( 270deg, #2AB6FF 0%, #4D8CFF 100%)')
        break;
      default:
        setBgVal('linear-gradient( 270deg, #2AB6FF 0%, #4D8CFF 100%)')
        break;
    }
  }

  /**
   * 点击
   */
  const onClickBtn = () => {
    clickCallback && clickCallback()
  }

  return <div style={{ width: '220px', height: '48px', cursor: 'pointer' }} onClick={onClickBtn} onMouseOver={onMouseOverBtn} onMouseOut={onMouseOutBtn}>
    <div className='w-full h-full relative rounded-full' style={{ background: bgVal }}>
      <div className='w-full h-full absolute flex flex-row justify-between items-center px-4'>
        <img src={starUrl} alt="" style={{ width: '26px', height: '26px', display: iconDisplay }} />
        <img src={arrowUrl} alt="" style={{ width: '12px', height: '12px', display: iconDisplay }} />
      </div>
      <div className="w-full h-full absolute text-base text-center" style={{ lineHeight: '48px', color: textColor }}>{btnText}</div>
    </div>
  </div>
}

export default SysBtn