
import cuband1 from '../../assets/img/product/cuband_1.png'
import cuband2 from '../../assets/img/product/cuband_2.png'

/**
 * Cuband便携式脑电仪
 * @returns 
 */
const ProductCuband = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      <div>
        <img style={{ width: '490px' }} src={cuband1} alt="" />
      </div>
      <div className='pt-8 pl-8' style={{ width: '645px' }}>
        <div className='text-2xl font-bold'>Cuband便携式脑电仪</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>Cuband便携式脑电仪，新智思维课堂的脑电训练设备之一，用于教学过程中的测评及训练。采用被动式干电极，它在前额部位（神经科学称之为FP1区）能够测量出可用于研究领域的高精度脑电信号，这些脑电信号经过复杂的运算处理后，被解读成多项反映人们大脑状态变化的参数，然后将脑电参数输入给训练系统及其他脑电外设。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>产品特点：</div>
      <div className='text-sm mt-4 leading-6'>相对现有医疗机构的脑电仪，CUBand具有如下优点：</div>
      <div className='text-sm mt-4 leading-6'>1.精度高：采用单颗芯片实现脑电信号采集、滤波、放大、A/D转换、计算等一体化工作，结合第五代嵌入式优化算法，采集的数据可用于研究领域。</div>
      <div className='text-sm mt-4 leading-6'>2.数据丰富：可以同时对外输出原始脑电数据和解算后的专注度、放松度、δ、θ、α1、α2、β1、β2、γ1、γ2频段8个EEG参数。</div>
      <div className='text-sm mt-4 leading-6'>3.易于使用：采用干电极传感器采集脑电信号，使用时无需涂抹导电胶，结构设计采用发带形式，佩戴方便快捷。</div>
      <div className='text-sm mt-4 leading-6'>4.安全舒适：采用非侵入式、被动式传感器，柔性耳夹，无感佩戴。</div>
      <div className='text-sm mt-4 leading-6'>5.无线通信：采用无线方式进行数据传输，摆脱传统脑电仪线缆束缚。</div>
      <div className='text-sm mt-4 leading-6'>6.适应性强：采用自适应算法，适用于不同的人群和日常环境。</div>
      <div className='text-sm mt-4 leading-6'>7.主要参数：</div>
      <div className='text-sm mt-4 leading-6'>通道数：单通道。</div>
      <div className='text-sm mt-4 leading-6'>电极：采用非侵入式干电极，无需导电胶或导电液。</div>
      <div className='text-sm mt-4 leading-6'>佩戴位置：前额（FP1），做到同步采集8个波段。</div>
      <div className='text-sm mt-4 leading-6'>信号采样率：512Hz。</div>
      <div className='text-sm mt-4 leading-6'>ADC精度：12位ADC。</div>
      <div className='text-sm mt-4 leading-6'>环境滤波：50Hz。</div>
      <div className='text-sm mt-4 leading-6'>输出眨眼强度检测数据，数值区间为1-255。</div>
      <div className='text-sm mt-4 leading-6'>采用自适应算法，可适用于不同的人群和环境。</div>
      <div className='text-sm mt-4 leading-6'>通讯模式：绿色ISM 2.4Ghz无线通讯，支持点对点模式和一对多模式，配合Mindswitch，可同步采集500人以上的脑电参数。</div>
      <img className='w-2/3 m-auto mt-4' src={cuband2} alt='' />
    </div>
  </div>
}

export default ProductCuband