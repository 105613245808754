
/**
 * 脑认知大数据
 * @returns 
 */
const ProductBMindata = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      {/* <div>
        <img style={{ width: '490px' }} src={penguin1} alt="" />
      </div> */}
      <div className='w-full pt-8'>
        <div className='text-2xl font-bold'>脑云梯-脑智综合评估系统</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>脑电认知大数据平台B-MindData是基于大脑360系统、新智思维课堂系统构建的脑智大数据云平台，平台融合了所有测评和训练过程中的认知行为数据和脑电数据，集成了数据存储、数据分析、数据展示等功能，并可通过API开放接口提供各项数据服务。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>平台功能特点：</div>
      <div className='text-sm mt-4 leading-6'>1.多维数据记录：平台包含大脑360系统所有测评、训练项目的行为数据及脑电数据，从多个维度记录了测评、训练、操作和对应的脑电数据，通过大数据的挖掘，可以分析各类操作行为背后的脑活动的机制。</div>
      <div className='text-sm mt-4 leading-6'>2.多维数据分析。平台提供了从认知领域、认知能力维度、认知项目到年龄分布、区域分布、机构分布等多个维度的数据统计、分析及深层次数据挖掘服务。</div>
      <div className='text-sm mt-4 leading-6'>3.多维数据展示：通过可视化平台进行大数据图形化多维展示，展示内容包括各项统计信息、学生脑发育和认知能力发展趋势信息、预警提示信息、训练成果展示信息等，为各级教育和培训机构提供内部学习情况监督、问题分析识别与预警、教育策略优化和学习效果评价等。</div>
      <div className='text-sm mt-4 leading-6'>4.开放访问接口：平台以API方式对外提供数据访问接口，实现对各级数据和统计分析信息的访问。</div>
      <div className='text-sm mt-4 leading-6'>5.安全措施：多层次数据安全性及隐私保护机制。</div>
    </div>
  </div>
}

export default ProductBMindata