
import thinkband1 from '../../assets/img/product/thinkband_1.png'

/**
 * Thinkband便携式脑电仪
 * @returns 
 */
const ProductThinkband = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      <div>
        <img style={{ width: '490px' }} src={thinkband1} alt="" />
      </div>
      <div className='pt-8 pl-8' style={{ width: '645px' }}>
        <div className='text-2xl font-bold'>Thinkband便携式脑电仪</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>ThinkBand脑电仪，新智思维课堂的脑电训练设备之一，用于训练前及训练阶段的脑功能发育基础测评。是在前几代脑电采集技术的基础之上，对信号采样频率、信号精度、ADC精度、动态响应、系统内部噪声、环境滤波等指标全面升级的双导脑电仪。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>突出优势：</div>
      <div className='text-sm mt-4 leading-6'>1.高性能：双脑电处理器融合架构，对脑电数据的采集效率提升了4倍。</div>
      <div className='text-sm mt-4 leading-6'>2.优化的算法：能高效识别源自心电、脑电和肌电的干扰生物电信号，结合滤波算法，使得Thinkband能胜任更广泛的研究项目。</div>
      <div className='text-sm mt-4 leading-6'>3.Thinkband中继器，硬件有更广泛的系统兼容性。</div>
      <div className='text-sm mt-4 leading-6'>4.通讯模式：绿色ISM 2.4Ghz无线通讯，支持点对点模式和一对多模式，配合Mindswitch，可同步采集500人以上的脑电参数。</div>
    </div>
  </div>
}

export default ProductThinkband