/**
 * 隐私政策
 * @returns 
 */
const Privacy = () => {
  return <div className="w-1200 m-auto h-full pt-6 pb-6">
    <div className="bg-white py-6 px-6">
      <div className="text-2xl font-semibold text-center mb-8">隐私政策</div>

      <p className="leading-6 text-right">生效日期：2024年10月30日</p>

      <p className="leading-6 indent-8">感谢您对北京视友科技有限责任公司（以下简称“我们”）的信任。为了帮助您了解我们对个人信息的处理和保护规则，我们特制定本《隐私政策》（以下简称“本政策”）。请在使用我们的产品（或服务）前，仔细阅读并了解本政策。如您有任何疑问、意见或建议，您可通过本政策提供的联系方式与我们联系。</p>
      <p className="leading-6 indent-8">本政策适用于我们通过新智思维课堂网站或新智思维课堂训练系统向您提供的产品或服务。为了更好地为您提供贴心的服务，您在使用我们的产品或服务时，我们可能会收集和使用您的相关信息。我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
      <p className="leading-6 indent-8">本隐私政策将帮助您了解以下内容：</p>
      <p className="leading-6 indent-8">一、 我们如何收集和使用您的个人信息</p>
      <p className="leading-6 indent-8">二、 征得授权同意的例外</p>
      <p className="leading-6 indent-8">三、 我们如何共享、转让、公开披露您的个人信息</p>
      <p className="leading-6 indent-8">四、 我们如何保护您的个人信息</p>
      <p className="leading-6 indent-8">五、 您的权利</p>
      <p className="leading-6 indent-8">六、未成年人用户信息的特别约定</p>
      <p className="leading-6 indent-8">七、 隐私政策的变更和修订</p>
      <p className="leading-6 indent-8">八、 如何联系我们</p>

      <p className="leading-8 font-bold"></p>
      <p className="leading-6 indent-8">一、我们如何收集和使用您的个人信息</p>
      <p className="leading-6 indent-8">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
      <p className="leading-6 indent-8">本产品会在注册、个人信息页收集您的个人信息。</p>
      <p className="leading-6 indent-8">本产品仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
      <p className="leading-6 indent-8">1.为完成创建账号，您可能需提供以下信息：您的姓名、手机号、电子邮箱地址、创建的用户名和密码。我们收集手机号码也为了验证您的身份，满足相关法律法规的网络实名制要求。</p>
      <p className="leading-6 indent-8">2.在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：年龄、性别、邮箱、地址、民族、学历、籍贯、职业、单位及职务、年级、班级、体重、身高、血型、利手、城乡、单位和兴趣爱好。但如果您不提供这些信息，将不会影响使用本产品的基本功能。我们收集这些信息，是为了给您推荐个性化的训练内容。</p>
      <p className="leading-6 indent-8">3.在使用过程中，需要使用我们指定的脑电设备进行认知测评和训练，在测评和训练过程中，我们需要记录脑电数据和测评训练过程中的正确率、反应时等各类操作数据，以对用户的测评和训练过程进行分析和评价，并向您提供这些结果信息和评估报告。您允许《隐私政策》并使用我们提供的这些功能，即表示您同意和允许我们收集、维护、处理、使用这些数据信息。</p>
      <p className="leading-6 indent-8">您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。</p>
      <p className="leading-6 indent-8">为确保本产品的正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的电脑上放置安全的Cookie及相关技术收集信息。Cookie 通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您使用本产品服务。当您使用本平台产品时，会向您的电脑发送Cookie。当您与我们提供给合作方的服务进行交互时，我们允许Cookie（或者其他匿名标识符）发送给我们的服务器。我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除保存的所有Cookie。但如果您这么做，则可能需要在每一次使用本产品时更改用户设置。</p>
      <p className="leading-6 indent-8">如果某一需要收集您的个人信息的功能或产品/服务未能在本隐私政策中予以说明的，或者我们超出与收集您的个人信息时所声称的目的使用您的个人信息前，或者我们对收集信息的方式、范围及目的进行更新时，我们会通过更新本隐私政策、页面提示、弹窗、消息通知、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您同意后收集和使用您的个人信息。</p>
      <p className="leading-6 indent-8">上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</p>

      <p className="leading-8 font-bold">二、征得授权同意的例外</p>
      <p className="leading-6 indent-8">根据相关法律法规规定，以下情形中收集和您的个人信息无需征得您的授权同意：</p>
      <p className="leading-6 indent-8">1.与我们履行法律法规规定的义务有关的；</p>
      <p className="leading-6 indent-8">2.与国家安全、国防安全直接有关的；</p>
      <p className="leading-6 indent-8">3.与公共安全、公共卫生、重大公共利益直接有关的；</p>
      <p className="leading-6 indent-8">4.与刑事侦查、起诉、审判和判决执行等直接有关的；</p>
      <p className="leading-6 indent-8">5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</p>
      <p className="leading-6 indent-8">6.所涉及的个人信息是您自行向社会公众公开的；</p>
      <p className="leading-6 indent-8">7.根据您要求签订和履行合同所必需的；</p>
      <p className="leading-6 indent-8">8.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p className="leading-6 indent-8">9.维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
      <p className="leading-6 indent-8">10.为合法的新闻报道所必需的；</p>
      <p className="leading-6 indent-8">11. 法律、法规或国家标准规定的其他情形。</p>

      <p className="leading-8 font-bold">三、我们如何共享、转让、公开披露您的个人信息</p>
      <p className="leading-6 indent-8">（一）共享</p>
      <p className="leading-6 indent-8">我们不会与任何公司、组织和个人共享您的个人信息，除非获得您的明确同意。但以下情况除外：</p>
      <p className="leading-6 indent-8">1.我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</p>
      <p className="leading-6 indent-8">2.在法律法规允许的范围内，为维护我们或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害而有必要共享您的个人信息。</p>
      <p className="leading-6 indent-8">3.与第三方合作伙伴共享。我们可能会委托第三方合作伙伴代我们处理个人信息，或与我们有业务合作关系的合作伙伴共享您的个人信息。但我们仅会出于合法、正当、必要的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。除非您另行授权同意，我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：</p>
      <p className="leading-6 indent-8">(1)提供技术、咨询服务的服务提供商。我们可能会将您的个人信息共享给支持我们提供服务的第三方服务提供商。这些服务提供商包括为我们提供基础设施技术服务、数据处理、数据分析、数据核验、公估、信用审查、风控和客户服务等的机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。</p>
      <p className="leading-6 indent-8">(2) 广告、分析服务类的授权合作伙伴。除非获得您的明确授权同意，否则我们不会与第三方广告商、分析服务提供商或其他合作伙伴共享您的个人信息。我们可能会向这些合作伙伴提供汇总后的信息、匿名化后的信息或者其他不会识别到您个人身份的信息，但不会分享您的任何身份信息、账号信息或其他可识别信息。我们会不时对该等合作伙伴或服务提供商进行技术检测和行为审计，并要求其遵循合作法律协议，以最大限度地确保其依法、依规、依约收集和使用数据。</p>
      <p className="leading-6 indent-8">（二）转让</p>
      <p className="leading-6 indent-8">我们不会将您的个人信息转让给其他任何公司、组织或个人，但以下情形除外：</p>
      <p className="leading-6 indent-8">1.事先获得您明确的同意或授权；获得您的明确同意或授权后，我们会向其他方转让您的个人信息；</p>
      <p className="leading-6 indent-8">2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
      <p className="leading-6 indent-8">3.符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
      <p className="leading-6 indent-8">4.在涉及合并、收购、资产转让或其他类似的交易时，如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受此政策约束，否则我们将要求该公司、组织和个人重新征得您的授权同意。</p>
      <p className="leading-6 indent-8">（三）披露</p>
      <p className="leading-6 indent-8">我们仅会在以下情况，且采取符合业界标准的安全防护措施的前提下，公开披露您的个人信息：</p>
      <p className="leading-6 indent-8">1.根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
      <p className="leading-6 indent-8">2.根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或核查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定核查目的且有合法权利获取的数据。</p>

      <p className="leading-8 font-bold">四、我们如何保护您的个人信息</p>
      <p className="leading-6 indent-8">我们已采取符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。</p>
      <p className="leading-6 indent-8">1.我们设置了专门的个人信息保护负责人，负责处理本产品和服务可能涉及到用户个人信息的各项事务，以及规划和制定公司的隐私政策、审核各产品的用户使用协议、监督各产品的工作原理和信息处理机制，我们部署访问控制机制，确保只有授权人员才可访问个人信息。</p>
      <p className="leading-6 indent-8">2.我们按照信息安全等级保护的要求制定了信息安全工作的总体方针和安全策略，建立了覆盖主机、数据、应用、管理等层面的安全管理制度，我们使用加密技术确保数据的保密性，我们设立了技术部为信息安全管理工作的职能部门，明确了安全管理机构内的各个部门和岗位的职责、分工和技能要求，制定了明确的人员录用和离职管理规范。</p>
      <p className="leading-6 indent-8">3.我们存储用户个人信息的服务器系统均为安全加固后的操作系统，我们使用受信赖的保护机制防止数据遭到恶意攻击。我们会对服务器操作进行账户审计及监控。如果发现外部公告有安全问题的服务器操作系统，我们会在第一时间进行服务器安全升级，确保所有服务器系统及应用安全。</p>
      <p className="leading-6 indent-8">4.我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>

      <p className="leading-8 font-bold">五、您的权利</p>
      <p className="leading-6 indent-8">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法， 我们保障您对自己的个人信息行使以下权利：</p>
      <p className="leading-6 indent-8">（一）访问和更正您的个人信息</p>
      <p className="leading-6 indent-8">您可以通过登录本产品个人账号来访问和更正您的个人信息。</p>
      <p className="leading-6 indent-8">如果您无法通过上述途径访问这些个人信息，您可以通过本政策提供的联系方式与我们联系。在确认您的用户身份后的15个工作日内向您提供相关服务。</p>
      <p className="leading-6 indent-8">（二）删除您的个人信息</p>
      <p className="leading-6 indent-8">以下情况发生时，您可以向我们提出删除个人信息的请求：</p>
      <p className="leading-6 indent-8">1、如果我们处理个人信息的行为违反法律法规；</p>
      <p className="leading-6 indent-8">2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <p className="leading-6 indent-8">3、如果我们处理个人信息的行为违反了与您的约定；</p>
      <p className="leading-6 indent-8">4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
      <p className="leading-6 indent-8">5、如果我们不再为您提供产品或服务。</p>
      <p className="leading-6 indent-8">您可以通过本政策提供的联系方式与我们联系，我们会在确认您的用户身份后的15个工作日内予以回复。当我们从服务器中删除您的个人信息后，我们可能不会立即从备份系统中删除相应的数据，但会在备份更新的时候删除这些信息。</p>
      <p className="leading-6 indent-8">（三）撤回您的授权或改变您授权同意的范围</p>
      <p className="leading-6 indent-8">请您理解，每个业务功能需要一些必要的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务和功能，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
      <p className="leading-6 indent-8">（四）响应您的上述请求</p>
      <p className="leading-6 indent-8">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
      <p className="leading-6 indent-8">在以下情形中，我们将无法响应您的请求：</p>
      <p className="leading-6 indent-8">1.与我们履行法律法规规定的义务相关的；</p>
      <p className="leading-6 indent-8">2.与国家安全、国防安全直接相关的；</p>
      <p className="leading-6 indent-8">3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p className="leading-6 indent-8">4.与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
      <p className="leading-6 indent-8">5.我们有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
      <p className="leading-6 indent-8">6.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p className="leading-6 indent-8">7.响应您的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
      <p className="leading-6 indent-8">8.涉及商业秘密的。</p>
      <p className="leading-6 indent-8">如果我们决定不响应您的请求，我们会向您告知该决定的理由，并向您提供投诉的途径。</p>

      <p className="leading-8 font-bold">六、未成年人用户信息的特别约定</p>
      <p className="leading-6 indent-8">如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>

      <p className="leading-8 font-bold">七、隐私政策的变更和修订</p>
      <p className="leading-6 indent-8">需要特别说明的是，本政策不适用于其他第三方向您提供的服务。我们会在与这些第三方开展合作过程中尽最大努力督促其对保护您的个人信息，但您理解这些服务由第三方独立向您提供，第三方将依照其隐私政策或用户协议单独对您的个人信息处理承担责任。</p>
      <p className="leading-6 indent-8">我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。</p>
      <p className="leading-6 indent-8">对于本隐私政策的重大变更，我们还会提供显著的通知（例如，在您重新登录时，以弹窗形式对您进行及时通知）。</p>
      <p className="leading-6 indent-8">本政策所指的重大变更包括但不限于：</p>
      <p className="leading-6 indent-8">1.我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型、个人信息的使用方式等；</p>
      <p className="leading-6 indent-8">2.我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</p>
      <p className="leading-6 indent-8">3.个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p className="leading-6 indent-8">4.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p className="leading-6 indent-8">5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
      <p className="leading-6 indent-8">6.个人信息安全影响评估报告表明存在高风险时。</p>

      <p className="leading-8 font-bold">八、如何联系我们</p>
      <p className="leading-6 indent-8">您可以通过邮箱联系我们：develop@cusoft.net</p>
      <p className="leading-6 indent-8">您也可以通过以下地址联系我们：北京经济技术开发区科创十二街北斗产业园2号楼A座415室</p>

    </div>
  </div>
}

export default Privacy