
/**
 * 产品
 * @returns 
 */
const SysProduct = ({ imgUrl, name, des, data, clickCallback }) => {

  /**
   * 点击 了解详情
   */
  const onClickProductItem = () => {
    clickCallback && clickCallback(data)
  }

  return <div className="bg-white page_product rounded-lg" style={{ width: '282px', height: '296px', margin: '0 12px 12px 12px', boxShadow: '0px 2px 15px -7px #000000' }}>
    <div className="w-full py-2" style={{ height: '158px' }}>
      <img className='h-full m-auto' src={imgUrl} alt="" />
    </div>
    <div className="w-full p-4 pt-2" style={{ height: '138px' }}>
      <div className="text-base font-bold">{name}</div>
      <div className="text-xs text-gray-500 mt-2">{des}</div>
      <div className=" flex flex-row justify-end mt-4">
        <div className="text-base w-max p-1 px-4 rounded-full text-sky-400 hover:bg-sky-600 cursor-pointer hover:text-white border border-sky-400" onClick={onClickProductItem}>了解详情</div>
      </div>
    </div>
  </div>
}

export default SysProduct