import { useState } from "react"
import { Button, Checkbox, Form, Input, message, Modal, Select } from "antd"
import useBearStore from "../../zustand/state"
import SysBtn from "../SysBtn"
import wximg from '../../assets/img/wx.png'
import cityData from '../../util/cities.json'
import { reqCooperate, reqSmsCode } from "../../util/api"

const provinceData = []
const genderOption = [
  {
    value: '1',
    label: '男',
  },
  {
    value: '2',
    label: '女',
  },
]

/**
 * 获取省数组
 */
const computedProvinceData = () => {
  for (let key in cityData) {
    provinceData.push(key)
  }
}

computedProvinceData()

/**
 * 试听 加盟
 * @returns 
 */
const SysModalConsult = () => {

  const [form] = Form.useForm()
  const { consultDisplay, setConsultDisplay } = useBearStore()
  const [checkedType, setCheckedType] = useState(false)
  const [cities, setCities] = useState(cityData[provinceData[0]])
  const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0])
  const [opacityVal, setOpacityVal] = useState(0)
  const [smsDisabled, setSmsDisabled] = useState(false)
  const [smsText, setSmsText] = useState('获取验证码')

  /**
   * 选择 省
   * @param {*} value 
   */
  const handleProvinceChange = (value) => {
    setCities(cityData[value])
    setSecondCity(cityData[value][0])
  }

  /**
   * 选择 市
   * @param {*} value 
   */
  const onSecondCityChange = (value) => {
    setSecondCity(value)
  }

  /**
   * 复选框 点击
   * @param {*} e 
   */
  const onCheckBoxChange = (e) => {
    setCheckedType(e.target.checked)
  }

  /**
   * 成功 消息
   * @param {*} msg 
   */
  const msgSuccess = () => {
    Modal.success({
      title: '成功',
      centered: true,
      content: (
        <div>
          <p>您已成功提交申请</p>
        </div>
      ),
      onOk() { 
        onCancelModal()
      },
    })
  }

  /**
   * 失败 消息
   * @param {*} msg 
   */
  const msgError = (msg) => {
    Modal.error({
      title: '失败',
      centered: true,
      content: (
        <div>
          <p>{msg}</p>
        </div>
      ),
      onOk() { },
    })
  }

  /**
   * 试听 提交
   * @param {*} values 
   */
  const onFinishAudition = (values) => {
    reqCooperate(values).then((res) => {
      if (res.res === 1) {
        msgSuccess()
      }
      else {
        msgError(res.msg)
      }
    })
  }

  /**
   * 提交
   */
  const onSubmit = () => {
    if (checkedType) {
      setOpacityVal(0)
      form.submit()
    }
    else {
      setOpacityVal(1)
    }
  }

  /**
   * 短信验证码
   */
  const onClickSms = () => {
    let phoneVal = form.getFieldValue('phone')
    reqSmsCode({ phone: phoneVal }).then((res) => {
      if (res.res === 1) {
        setSmsDisabled(true)
        getSms()
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  /**
   * 倒计时
   */
  const getSms = () => {
    let timeNum = 121
    let timeOut = setInterval(() => {
      setSmsText(timeNum + '后重发')
      timeNum--
      if (timeNum === 0) {
        clearInterval(timeOut)
        setSmsText('获取验证码')
        setSmsDisabled(false)
      }
    }, 1000)
  }

  /**
   * 关闭弹框
   */
  const onCancelModal = () => {
    form.resetFields()
    setCheckedType(false)
    setOpacityVal(0)
    setConsultDisplay(false)
  }

  return <Modal
    centered
    open={consultDisplay}
    onCancel={onCancelModal}
    footer={null}
    width={544}
    maskClosable={false}
    destroyOnClose={true}
  >
    <div className="w-full">
      <div className="text-center text-xl font-bold">加盟合作咨询</div>
      <div className="text-center text-sm text-gray-400 mt-4">感谢关注。若您想咨询加盟方案，可拨打400-005-8128进行电话咨询，</div>
      <div className="text-center text-sm text-gray-400 mt-0">或扫描下方二维码加企业微信沟通。您也可以留下您的联系方式，</div>
      <div className="text-center text-sm text-gray-400 mt-0">稍后由我们的工作人员和您联系。</div>

      {/* 试听 */}
      <div>
        <Form
          name="basic"
          form={form}
          onFinish={onFinishAudition}
          autoComplete="off"
          preserve={false}
        >
          <div>

            <div className="flex flex-row  items-center mt-6">
              <div className="w-20 text-lg font-bold text-right pr-2">机构名称</div>
              <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '400px' }}>
                <Form.Item name="group_name" rules={[{ required: true, message: '请输入机构名称' }]}>
                  <Input className="outline-none w-full h-12 bg-transparent px-4" placeholder="请输入机构名称" />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-row  items-center mt-6">
              <div className="w-20 text-lg font-bold text-right pr-2">所处城市</div>
              <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '190px' }}>
                <Form.Item name="province" rules={[{ required: true, message: '请选择省' }]}>
                  <Select
                    className="w-full h-12 text-center"
                    placeholder='请选择省'
                    onChange={handleProvinceChange}
                    options={provinceData.map((province) => ({ label: province, value: province }))}
                  />
                </Form.Item>
              </div>
              <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '190px' }}>
                <Form.Item name="city" rules={[{ required: true, message: '请选择市' }]}>
                  <Select
                    className="w-full h-12 text-center"
                    placeholder='请选择市'
                    value={secondCity}
                    onChange={onSecondCityChange}
                    options={cities.map((city) => ({ label: city, value: city }))}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-row  items-center mt-6">
              <div className="w-20 text-lg font-bold text-right pr-2">联系人</div>
              <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '190px' }}>
                <Form.Item name="name" rules={[{ required: true, message: '请输入学生姓名' }]}>
                  <Input className="outline-none w-full h-12 bg-transparent px-4" placeholder="请输入学生姓名" />
                </Form.Item>
              </div>

              <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '190px' }}>
                <Form.Item name="gender" rules={[{ required: true, message: '请选择性别' }]}>
                  <Select
                    placeholder="请选择性别"
                    className="w-full h-12 text-center"
                    options={genderOption}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-row  items-center mt-6">
              <div className="w-20 text-lg font-bold text-right pr-2">手机号码</div>
              <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '400px' }}>
                <Form.Item name="phone" rules={[{ required: true, message: '请输入手机号码' }]}>
                  <Input className="outline-none w-full h-12 bg-transparent px-4" placeholder="请输入手机号码" />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="flex flex-row  items-center mt-6">
            <div className="w-20 text-lg font-bold text-right pr-2">验证码</div>
            <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '190px' }}>
              <Form.Item name="code" rules={[{ required: true, message: '请输入验证码' }]}>
                <Input className="outline-none w-full h-12 bg-transparent px-4" placeholder="请输入验证码" />
              </Form.Item>
            </div>
            <div className="h-12 rounded-lg bg-gray-100 ml-4" style={{ width: '190px' }}>
              <Button className="w-full h-full" type="primary" disabled={smsDisabled} onClick={onClickSms}>{smsText}</Button>
            </div>
          </div>

        </Form>
      </div>

      <div className="flex flex-row justify-center mt-6">
        <SysBtn btnText='提交' btnType='' clickCallback={onSubmit} />
      </div>
      <div className="flex flex-row justify-center items-center mt-6">
        <div><Checkbox onChange={onCheckBoxChange}></Checkbox></div>
        <div className="text-sm text-gray-400 ml-2">同意隐私政策</div>
      </div>

      <div className="w-full text-red-500 text-center" style={{ opacity: opacityVal }}>需同意隐私政策</div>

      <img className="w-full mt-4" src={wximg} alt="" />
    </div>
  </Modal>
}

export default SysModalConsult