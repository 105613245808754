import { Outlet } from "react-router-dom"
import { ConfigProvider, Layout, theme } from "antd"
import SysHeader from "../../components/SysHeader"
import SysFooter from "../../components/SysFooter"
import SysFloatBtn from "../../components/SysFloatBtn"
import SysModalAudition from "../../components/SysModal/audition"

import './index.css'
import SysModalConsult from "../../components/SysModal/consult"
import SysModalDownload from "../../components/SysModal/download"

const { Content } = Layout

/**
 * 布局
 * @returns 
 */
const App = () => {

  // const [messageApi, contextHolder] = message.useMessage();

  return <ConfigProvider
    theme={{
      token: {
        // colorPrimary: '#38bdf8'，
        // colorBorder: 'transparent'
      },
      algorithm: theme.defaultAlgorithm,
      components: {
        Layout: {
          headerBg: '#fff',
          footerBg: '#193b52',
        },
        Pagination: {
          // itemLinkBg:'#ffffff'
        },
        Select: {
          selectorBg: '#f3f4f6'
        }
      },
    }}>
    <Layout>
      <SysHeader />
      <Content style={{}} >

        {/* 在此渲染子路由 */}
        <Outlet />

        {/* 悬浮按钮 */}
        <SysFloatBtn btnType='consult' />
        <SysFloatBtn btnType='audition' />

      </Content>
      <SysFooter />
      <SysModalAudition />
      <SysModalConsult />
      <SysModalDownload />
    </Layout>
  </ConfigProvider >
}

export default App