import axios from "axios";

// 创建axios实例
let request = axios.create({

  // 基础路径
  baseURL: process.env.REACT_APP_API_URL, //基础路径
  timeout: 5000, //超时时间
})

// request 实例添加请求与响应拦截器
request.interceptors.request.use((config) => {

  // headers请求头，
  // let store = useMainStore()
  // if (store.getToken()) {
  //   config.headers.token = store.getToken()
  // }

  // let tempToken:string = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzE1NzgsInVzZXJfZ3VpZCI6Ins1RDE4NDQ2OS04Nzc2LTEzRDctQTQ1OS03QTIyMEQ3MzZDRDV9IiwiYmluZF9waG9uZSI6IjE4NTEwMjYyNjczIiwiZnVsbF9uYW1lIjoiXHU4ZDg1XHU3ZmE0IiwibG9naW5fbmFtZSI6IjE4NTEwMjYyNjczIiwicm9sZSI6MiwiZXhwIjoxNjk0MjI0Njc2fQ.1Gw7bQi1oXVITtQRza6SvlQ3N5h5aaoyvcmjtYfzPoM'
  // config.headers.token = tempToken

  // 返回配置对象
  return config
})

// 响应拦截器
request.interceptors.response.use((response) => {
  // 成功回调

  // 简化数据
  return response.data
}, (error) => {

  // 失败回调
  let message = ''

  let status = error.response.status
  switch (status) {
    case 401:
      message = 'token过期'
      break;
    case 403:
      message = '无权访问'
      break;
    case 404:
      message = '请求地址错误'
      break;
    case 500:
      message = '服务器错误'
      break;
    default:
      message = '网络出现问题'
      break;
  }

  // 提示错误信息
  console.log(message)

  return Promise.reject(error)
})

export default request