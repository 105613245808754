
import cooperate1 from '../../assets/img/cooperate/cooperate1.png'
import cooperate2 from '../../assets/img/cooperate/cooperate2.png'
import cooperate3 from '../../assets/img/cooperate/cooperate3.png'
import cooperate4 from '../../assets/img/cooperate/cooperate4.png'
import cooperate5 from '../../assets/img/cooperate/cooperate5.png'
import cooperate6 from '../../assets/img/cooperate/cooperate6.png'
import cooperate7 from '../../assets/img/cooperate/cooperate7.png'
import cooperate8 from '../../assets/img/cooperate/cooperate8.png'
import SysAuditionBanner from '../../components/SysAuditionBanner'
import SysBtn from '../../components/SysBtn'
import SysExhibit from '../../components/SysExhibit'
import useBearStore from '../../zustand/state'

/**
 * 加盟合作
 * @returns 
 */
const Cooperate = () => {

  const { setConsultDisplay } = useBearStore()

  /**
   * 点击加盟
   */
  const onClickConsult = () => {
    setConsultDisplay(true)
  }

  return <div className="w-full max-w-1920 min-w-1200 h-full bg-white mx-auto page_cooperate">

    <div className='w-1200 pt-8 mx-auto'>
      <div className='w-full relative' style={{ height: '400px' }}>
        <img className='w-full h-full absolute z-0' src={cooperate1} alt='' />
        <div className='absolute' style={{ left: '82px', bottom: '132px' }}>
          <SysBtn btnType='cooperateBtn' btnText='加盟合作' clickCallback={onClickConsult} />
        </div>
      </div>
    </div>

    {/* 1 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>交钥匙式课程资源，所得即所用</div>
      <div className='w-1/4 text-sm text-center rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>课程资源所见即所得，所得及所用</div>
      <img className='w-full' src={cooperate2} alt='' />
    </div>

    {/* 2 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>高效教学管理，事半功倍</div>
      <div className='w-2/5  text-sm text-center rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>三个平台一体化，课堂 - 家长 -学生无缝衔接，全程呵护孩子成长</div>
      <img className='w-full' src={cooperate3} alt='' />
      <div className='w-1/2 flex flex-row justify-center items-center mx-auto mt-6'>
        <div className='flex flex-row items-center mr-8'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>教师备课在线管理</div>
        </div>
        <div className='flex flex-row items-center mr-8'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>授课过程自动引导</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>测评报告自动生成</div>
        </div>
      </div>
      <div className='w-1/2 flex flex-row justify-center items-center mx-auto my-2'>
        <div className='flex flex-row items-center mr-8'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>阶段报告自动生成</div>
        </div>
        <div className='flex flex-row items-center mr-8'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>咨询报名在线管理</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>家校互动在线管理</div>
        </div>
      </div>
    </div>

    {/* 3 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>工欲善其事，必先利其器</div>
      <div className='w-2/5 text-sm text-center rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>专业的脑电反馈设备，为测评、训练提供真实客观的生理参数</div>
      <img className='w-full' src={cooperate4} alt='' />
    </div>

    {/* 4 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>让孩子的成长看的见</div>
      <div className='w-1/3 text-sm text-center rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>教学过程数据化，训练效果提升可视化</div>
      <img className='w-full' src={cooperate5} alt='' />
      <div className='w-1/2 flex flex-row justify-evenly items-center mx-auto my-6'>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>家长体验可视化</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>测评过程可视化</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>训练过程可视化</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>教学效率可视化</div>
        </div>
      </div>
    </div>

    {/* 5 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>服务理念，授之于鱼，不如授之以渔</div>
      <div className='w-1/4 text-sm text-center rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>师资培训，全程教练式一对一帮扶</div>
      <img className='w-full' src={cooperate6} alt='' />
    </div>

    {/* 6 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>完整的技术生态链，保护合作伙伴利益</div>
      <img className='mt-12' src={cooperate7} alt='' style={{ width: '1055px' }} />
    </div>

    {/* 7 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='w-auto text-4xl text-center font-bold'>严谨的加盟合作流程</div>
      <div className='w-1/3 text-sm text-center rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>可持续化运营，让更多的孩子受益脑科学</div>
      <img className='w-full' src={cooperate8} alt='' />
    </div>

    {/* 荣誉与资质 */}
    <SysExhibit />

    {/* 试听 */}
    <SysAuditionBanner />

  </div>
}

export default Cooperate