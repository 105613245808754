
import penguin1 from '../../assets/img/product/penguin1.png'
import penguin2 from '../../assets/img/product/penguin2.png'
import penguin3 from '../../assets/img/product/penguin3.png'

/**
 * 脑云梯-脑智综合评估系统
 * @returns 
 */
const ProductPenguin = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      <div>
        <img style={{ width: '490px' }} src={penguin1} alt="" />
      </div>
      <div className='pt-8 pl-8' style={{ width: '645px' }}>
        <div className='text-2xl font-bold'>脑云梯-脑智综合评估系统</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>脑云梯-脑智综合评估系统，新智思维课堂的脑电系统之一。评估学生大脑功能的基础发育水平，从静息态的角度评估个体大脑能力的生理条件。</div>
        <div className='text-sm mt-4 leading-6'>提供包括基于脑电的脑发育评估、基于标准化实验范式的认知发育测评以及结合脑电和行为数据的综合评价等多种测评方案，并结合大数据分析算法给出评价报告。</div>
        <div className='text-sm mt-4 leading-6'>脑云梯部署于互联网云端，用户通过浏览器即可登录和使用。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>产品功能特点：</div>
      <div className='text-sm mt-4 leading-6'>1.基于多导联脑机接口技术的脑功能检测功能。</div>
      <div className='text-sm mt-4 leading-6'>2.采用基于脑电大数据平台的AI算法，输出大脑发育基础节律、优势节律、左右脑均衡性、注意指数、放松指数、脑状况指数、脑活性指数、情绪指数、脑负荷能力、焦虑指数、疲劳指数、强迫指数、睡眠指数、抗压指数等指标。</div>
      <div className='text-sm mt-4 leading-6'>3.支持认知能力综合测评，实现对感知觉、注意、记忆、空间、情绪、思维等认知能力的综合测评。 并对认知能力进行二级维度的专项测评，自动生成脑发育评估报告。</div>
      <img className='w-2/3 m-auto mt-8' src={penguin2} alt='' />
      <img className='w-1/2 m-auto mt-8' src={penguin3} alt='' />
    </div>
  </div>
}

export default ProductPenguin