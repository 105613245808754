import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Layout, Menu } from "antd"
import { PhoneOutlined } from '@ant-design/icons'
import logo from '../../assets/img/logo.png'

import './index.css'


const { Header } = Layout

const menuItems = [
  {
    label: '首页',
    key: 'home',
  },
  {
    label: '加盟合作',
    key: 'cooperate',
  },
  {
    label: '脑电产品',
    key: 'productlist',
  },
  {
    label: '新闻资讯',
    key: 'newslist',
  },
]

/**
 * 头部
 * @returns 
 */
const SysHeader = () => {

  const naviget = useNavigate()
  const [curSelectedKeys, setCurSelectedKeys] = useState([''])

  useEffect(() => {
    setActiveMemuWithPath()
  }, [])

  /**
   * 根据地址查找菜单
   * @returns 
   */
  const setActiveMemuWithPath = () => {
    const pathname = window.location.pathname
    const pathArray = pathname ? pathname.split('/') : []

    if (pathArray.length === 0) {
      return false
    }

    let pathStr = pathArray[pathArray.length - 1]

    for (let i = 0; i < menuItems.length; i++) {
      const isExistPath = new RegExp(`^${menuItems[i].key}`).test(pathStr)
      if (isExistPath) {
        setCurSelectedKeys([menuItems[i].key])
        return
      }
    }
  }

  /**
   * 点击菜单
   * @param {*} param0 
   */
  const onClickMemu = ({ key }) => {
    setCurSelectedKeys([key])
    if (key === 'home') {
      naviget('/')
    }
    else {
      naviget('/' + key)
    }
  }

  return <Header className="sysheader w-full p-0 overflow-hidden">
    <div className="relative w-1200 m-auto h-full">
      <div className="absolute left-0 top-0 h-full flex flex-row items-center ">
        <div
          className="cursor-pointer"
          style={{
            width: '210px',
            height: '50px',
            backgroundImage: `url(${logo})`,
            backgroundSize: '100% 100%',
            marginRight: '4rem'
          }}
          onClick={() => { onClickMemu({ key: 'home' }) }}>
        </div>
        <Menu
          mode="horizontal"
          selectedKeys={curSelectedKeys}
          items={menuItems}
          style={{ flex: 1, minWidth: 0 }}
          onClick={onClickMemu}
        />
      </div>
      <div className="absolute right-0 top-0 h-full flex flex-row">
        <PhoneOutlined className="text-sky-400 mr-1" rotate={90} />
        <div className="text-base text-sky-400" style={{ lineHeight: '64px' }}>400-005-8128</div>
      </div>
    </div>
  </Header>
}

export default SysHeader