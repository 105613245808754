import request from './request'

/**
 * 申请免费试听
 * @param data 
 * @returns 
 */
export const reqAudition = (data) => request.post('/trial-class/apply', data)

/**
 * 加盟合作
 * @param data 
 * @returns 
 */
export const reqCooperate = (data) => request.post('/cooperation/make', data)

/**
 * 发送手机验证码
 * @param data 
 * @returns 
 */
export const reqSmsCode = (data) => request.post('/sms/send-code', data)

/**
 * 新闻列表
 * @param data 
 * @returns 
 */
export const reqArticleList = (data) => request.get('/article/list', { params: data })

/**
 * 新闻详情
 * @param data 
 * @returns 
 */
export const reqArticleDetail = (data) => request.get('/article/detail', { params: data })

/**
 * 创建新闻
 * @param data 
 * @returns 
 */
export const reqArticleCreate = (data) => request.post('/article/create', data)

/**
 * 编辑新闻
 * @param data 
 * @returns 
 */
export const reqArticleUpdate = (data) => request.post('/article/update', data)

/**
 * 删除新闻
 * @param data 
 * @returns 
 */
export const reqArticleDel = (data) => request.post('/article/del', data)


/**
 * 上传新闻图片
 * @param data 
 * @returns 
 */
export const reqArticleUploadImage = (data) => request.post('/article/upload-image', data)