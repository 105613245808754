import { create } from "zustand"

/**
 * zustand
 */
const useBearStore = create((set) => ({
  auditionDisplay: false,
  consultDisplay: false,
  downloadDisplay: false,
  newsCreateEditDisplay: false,
  newsDelDisplay: false,
  setAuditionDisplay: (value) => set({ auditionDisplay: value }),
  setConsultDisplay: (value) => set({ consultDisplay: value }),
  setDownloadDisplay: (value) => set({ downloadDisplay: value }),
  setNewsCreateEditDisplay: (value) => set({ newsCreateEditDisplay: value }),
  setNewsDelDisplay: (value) => set({ newsDelDisplay: value }),
}))

export default useBearStore