import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import App from "../pages/App";
import Cooperate from "../pages/Cooperate";
import ProductList from "../pages/ProductList";
import NewsList from "../pages/NewsList";
import ServiceAgreement from "../pages/ServiceAgreement";
import Privacy from "../pages/Privacy";
import NotFound from "../pages/NotFound";
import ProductMindlight from "../pages/Products/mindlight";
import ProductCuband from "../pages/Products/cuband";
import ProductThinkband from "../pages/Products/thinkband";
import ProductEgox from "../pages/Products/egox";
import ProductBrainstar from "../pages/Products/brainstar";
import ProductPenguin from "../pages/Products/penguin";
import ProductBMindata from "../pages/Products/bmindata";
import ProductOthers from "../pages/Products/others";
import NewsTool from "../pages/NewsTool";
import NewsDetail from "../pages/NewsDetail";

/**
 * 路由实例 router
 * 
 * 作用：绑定路由，导出实例对象
 */
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        // path: 'home',
        element: <Home />,
        index: true
      },
      {
        path: '/cooperate',
        element: <Cooperate />
      },
      {
        path: '/productlist',
        element: <ProductList />
      },
      {
        path: '/newslist',
        element: <NewsList />
      },
      {
        path: '/newsdetail',
        element: <NewsDetail />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/serviceagreement',
        element: <ServiceAgreement />
      },
      {
        path: '/privacy',
        element: <Privacy />
      },
      {
        path: '/mindlight',
        element: <ProductMindlight />
      },
      {
        path: '/cuband',
        element: <ProductCuband />
      },
      {
        path: '/thinkband',
        element: <ProductThinkband />
      },
      {
        path: '/egox',
        element: <ProductEgox />
      },
      {
        path: '/brainstar',
        element: <ProductBrainstar />
      },
      {
        path: '/penguin',
        element: <ProductPenguin />
      },
      {
        path: '/bmindata',
        element: <ProductBMindata />
      },
      {
        path: '/others',
        element: <ProductOthers />
      },
      {
        path: '/newstool',
        element: <NewsTool />,
        
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  },
],
  {
    basename: "",
  }
)

export default router