

import { useNavigate } from 'react-router-dom'
import banner1 from '../../assets/img/banner1.png'
import SysProduct from '../../components/SysProduct'
import mindlight from '../../assets/img/product/mindlight_1.png'
import cuband from '../../assets/img/product/cuband_1.png'
import thinkband from '../../assets/img/product/thinkband_1.png'
import egox from '../../assets/img/product/egox.png'
import brainstar from '../../assets/img/product/brainstar_1.png'
import penguin from '../../assets/img/product/penguin_1.png'
import BMindata from '../../assets/img/product/BMindata_1.png'
import others from '../../assets/img/product/others_1.png'

const productListData = [
  {
    id: '1',
    name: '思语脑波灯',
    des: '通过脑波灯的色彩变化可直观了解学生的情绪状态、用脑状态、注意力状态和放松水平。',
    imgUrl: mindlight,
    path: 'mindlight'
  },
  {
    id: '2',
    name: 'Cuband便携式脑电仪',
    des: '采用被动式无创干电极，佩戴便捷，能够测量出可用研究领域的高精度脑电数据。',
    imgUrl: cuband,
    path: 'cuband'
  },
  {
    id: '3',
    name: 'Thinkband便携式脑电仪',
    des: '采用双EEG处理器融合架构，被动式干电极，佩戴快捷，各项性能全面升级的双导脑电仪。',
    imgUrl: thinkband,
    path: 'thinkband'
  },
  {
    id: '4',
    name: 'Egox便携式脑电仪',
    des: '第六代超便携脑电仪，仅53克，时尚外观，几乎无佩戴感，EEG双核处理器,支持多平台。',
    imgUrl: egox,
    path: 'egox'
  },
  {
    id: '5',
    name: '脑力星',
    des: '新智思维课堂的智能脑电外设之一，应用灵活便捷，将光电传感、振动传感、脑电融合一起。',
    imgUrl: brainstar,
    path: 'brainstar'
  },
  {
    id: '6',
    name: '脑云梯-脑智综合评估系统',
    des: '从大脑功能基础发育的维度评估大脑的生理条件, 是新智思维课堂的重要测评项之一。',
    imgUrl: penguin,
    path: 'penguin'
  },
  {
    id: '7',
    name: 'B-Mindata脑认知大数据',
    des: '脑认知大数据AI云平台，新智思维课堂底层的核心支撑，记录分析测评训练和脑电数据。',
    imgUrl: BMindata,
    path: 'bmindata'
  },
  {
    id: '8',
    name: '脑机互动体验产品',
    des: '多种炫酷的脑机互动体验，是展览馆、招生会、科技节的引流网红爆款产品。可提供租赁服务。',
    imgUrl: others,
    path: 'others'
  },
]

/**
 * 产品列表
 * @returns 
 */
const ProductList = () => {

  const navigate = useNavigate()

  const onClickProduct = (data) => {
    navigate('/' + data.path)
  }

  return <div className="w-full max-w-1920 min-w-1200 h-full mx-auto bg-white pb-6 page_productlist">
    <div className='w-full pt-16'>
      <div className='w-full bg-cover' style={{ height: '220px', backgroundImage: `url(${banner1})`, backgroundPositionX: 'center' }}></div>
    </div>

    <div className='w-full m-auto mt-16 rounded-3xl flex flex-wrap' style={{ width: '1224px' }}>
      {productListData.map((item) => { return <SysProduct key={'product_' + item.id} imgUrl={item.imgUrl} name={item.name} des={item.des} data={item} clickCallback={onClickProduct} /> })}
    </div>
  </div>
}

export default ProductList