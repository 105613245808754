import { Button, message, Select, Table } from "antd"
import { useEffect, useState } from "react"
import { reqArticleList } from "../../util/api"
import useBearStore from "../../zustand/state"
import NewsToolDel from "./delete"
import NewsToolCreateEdit from "./createAndEdit"

/**
 * 新闻列表
 * @returns 
 */
const NewsToolList = () => {

  const { newsCreateEditDisplay, setNewsDelDisplay, setNewsCreateEditDisplay } = useBearStore()

  // 新闻类型 选项
  const options = [
    { value: '1', label: '公司新闻' },
    { value: '2', label: '家长课堂' },
    { value: '3', label: '科学用脑' },
  ]

  const columns = [
    {
      title: 'title',
      dataIndex: 'title',
    },
    {
      title: 'operate',
      dataIndex: 'operator',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        return (
          <Button onClick={() => { onClickEdit(record) }}>修改文章</Button>
        )
      }
    },
  ]

  // 新闻类型
  const [newsType, setNewsType] = useState('1')
  // 新闻数据
  const [dataSource, setDataSource] = useState([])
  // 选中的新闻
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // 操作类型
  const [operateType, setOperateType] = useState('')
  // 
  const [selectedNewsData, setSelectedNewsData] = useState(null)

  useEffect(() => {
    reqNewsList()
  }, [newsType])

  useEffect(() => {

  }, [selectedNewsData])

  /**
   * 获取新闻列表
   */
  const reqNewsList = () => {
    reqArticleList({ type: newsType }).then((res) => {
      if (res.res === 1) {
        res.list.forEach(element => {
          element.key = element.id
        })
        setDataSource(res.list)
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  /**
   * 更改新闻类型
   * @param {*} value 
   */
  const onChangeNewsType = (value) => {
    setNewsType(value)
  }

  /**
   * 选中行
   * @param {*} newSelectedRowKeys 
   */
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }

  /**
   * 选中 行
   */
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  /**
   * 创建新闻
   */
  const onClickCreate = () => {
    setSelectedNewsData(null)
    setOperateType('create')
    setNewsCreateEditDisplay(true)
  }

  /**
   * 修改新闻
   * @param {*} data 
   */
  const onClickEdit = (data) => {
    setSelectedNewsData(data)
    setOperateType('edit')
    setNewsCreateEditDisplay(true)
  }

  /**
   * 删除新闻
   */
  const onClickDel = () => {
    if (selectedRowKeys.length) {
      setNewsDelDisplay(true)
    }
    else {
      message.warning('请选择要删除的文章')
    }
  }

  /**
   * 更新列表
   */
  const updateCallback = () => {
    reqNewsList()
  }

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_newstool">
    <div>
      <span>文章类型：</span>
      <Select className="w-30" options={options} defaultValue='1' onChange={onChangeNewsType} />
      <Button className="ml-8" type="primary" onClick={onClickCreate}>创建文章</Button>
      <Button className="ml-8" type='primary' danger onClick={onClickDel}>删除文章</Button>
    </div>
    <div className="mt-8">
      <Table
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        rowSelection={rowSelection}
      />
    </div>

    {newsCreateEditDisplay && <NewsToolCreateEdit operateType={operateType} newsData={selectedNewsData} callback={updateCallback} />}
    <NewsToolDel delIds={selectedRowKeys} callback={updateCallback} />
  </div>
}

export default NewsToolList