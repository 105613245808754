
import mindlight1 from '../../assets/img/product/mindlight_1.png'
import mindlight2 from '../../assets/img/product/mindlight_2.jpg'

/**
 * 思语脑波灯
 * @returns 
 */
const ProductMindlight = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      <div style={{ width: '490px' }}>
        <img className='w-1/2 m-auto' src={mindlight1} alt="" />
      </div>
      <div className='pt-8 pl-8' style={{ width: '645px' }}>
        <div className='text-2xl font-bold'>思语脑波灯</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>思语脑波灯是基于脑机接口技术的智能外设，新智思维课堂的脑电训练设备之一，实现了情绪状态、用脑状态的可视化。用于注意力训练、脑放松训练、正念冥想及心理压力的测量等。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>使用场景：</div>
      <div className='text-sm mt-4 leading-6'>学生进行注意力、情绪力、思维力等大脑认知能力训练时，老师或家长可以借助脑波灯的色彩及变化规律，实时了解学生当下的用脑状态：是注意集中或是走神了，是心不在焉或是全力以赴，老师根据这些特征为学生定制训练方案。</div>
      <div className='text-base font-bold mt-4'>功能描述：</div>
      <div className='text-sm mt-4 leading-6'>CUBand或Thinkband便携式脑电仪采集学生大脑的脑波参数，传送给训练系统和脑波灯。脑波灯随着大脑的意识状态变化，按照“紫、红、绿、青、蓝”的颜色和亮度，显示大脑专注度或放松度的强度。在灯光色彩的引导下，学生可反观内心的状态并调整自己的情绪和注意力。</div>
      <div className='text-sm mt-4 leading-6'>思语脑波灯也可脱离训练系统单独使用。对那些有焦虑、紧张习惯的群体，思语脑波灯作为直观精确的生物反馈设备，可以帮助体验者在短短的10分钟左右，快速恢复精力、调整情绪</div>
      <img className='w-1/2 m-auto mt-4' src={mindlight2} alt='' />
    </div>
  </div>
}

export default ProductMindlight