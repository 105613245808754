import { useState } from "react"
import { FloatButton } from "antd"
import useBearStore from "../../zustand/state"
import consultBtn1 from '../../assets/img/consultBtn1.png'
import consultBtn2 from '../../assets/img/consultBtn2.png'
import auditionBtn1 from '../../assets/img/auditionBtn1.png'
import auditionBtn2 from '../../assets/img/auditionBtn2.png'
import './index.css'

/**
 * 悬浮按钮 页面右下角
 * @param {*} props 
 * @returns 
 */
const SysFloatBtn = ({ btnType }) => {

  const { setAuditionDisplay, setConsultDisplay } = useBearStore()
  const bottomVal = btnType === 'consult' ? '25rem' : '20rem'
  const [btnContent, setBtnContent] = useState(<div className="w-full h-full bg-contain m-auto" style={{ backgroundImage: `url(${btnType === 'consult' ? consultBtn1 : auditionBtn1})` }}></div>)

  /**
   * 鼠标移入
   */
  const onMouseOverBtn = () => {
    setBtnContent(<div className="w-full h-full bg-contain m-auto" style={{ backgroundImage: `url(${btnType === 'consult' ? consultBtn2 : auditionBtn2})` }}></div>)
  }

  /**
   * 鼠标移出
   */
  const onMouseOutBtn = () => {
    setBtnContent(<div className="w-full h-full bg-contain m-auto" style={{ backgroundImage: `url(${btnType === 'consult' ? consultBtn1 : auditionBtn1})` }}></div>)
  }

  /**
   * 点击
   */
  const onClickBtn = () => {
    if (btnType === 'consult') {
      setConsultDisplay(true)
    }
    else if (btnType === 'audition') {
      setAuditionDisplay(true)
    }
  }

  return <div>
    <FloatButton
      className="sysFloatBtn"
      style={{ width: '60px', height: '60px', right: '1.5rem', bottom: bottomVal }}
      tooltip={<div>{btnType === 'consult' ? '合作咨询' : '试听'}</div>}
      onClick={onClickBtn}
      icon={<div className="w-10 h-10" style={{ margin: '10px' }} onMouseOver={onMouseOverBtn} onMouseOut={onMouseOutBtn}>{btnContent}</div>}
    />
  </div>
}

export default SysFloatBtn