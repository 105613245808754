import { useNavigate } from "react-router-dom"

/**
 * 新闻
 * @returns 
 */
const SysNewsItem = (props) => {

  const { img, title, create_time, id } = props.newsData
  const naviget = useNavigate()

  /**
   * 点击新闻
   */
  const onClickNews = () => {
    naviget('/newsdetail?id=' + id)
  }

  // return <div className="flex flex-row justify-between mx-2 mb-8 cursor-pointer" style={{ width: '450px', height: '105px' }}>
  //   <img className='h-full' style={{ width: '150px' }} src={img} alt="" />
  //   <div className='flex flex-col justify-between items-start px-2'>
  //     <div className='text-base font-bold'>南京新智注意力训练中心成立，将会帮助更多</div>
  //     <div className='text-sm text-gray-400'>2023年06月13日</div>
  //   </div>
  // </div>

  return <div className="flex flex-row mx-2 mb-8 cursor-pointer" style={{ width: '450px', height: '105px' }} onClick={onClickNews}>
    <img className='h-full rounded-lg' style={{ width: '150px' }} src={img} alt="" />
    <div className='flex flex-col justify-between items-start p-2' style={{ width: '300px' }}>
      <div className='text-base font-bold'>{title}</div>
      <div className='text-sm text-gray-400'>{create_time.substring(0, 10)}</div>
    </div>
  </div>
}

export default SysNewsItem