
import useBearStore from '../../zustand/state'
import ayou from '../../assets/img/home/ayou.png'
import SysBtn from '../SysBtn'

/**
 * 试听 banner
 * @returns 
 */
const SysAuditionBanner = () => {

  const { setConsultDisplay } = useBearStore()

  /**
   * 点击试听
   */
  const onClickAudition = () => {
    setConsultDisplay(true)
  }

  return <div className='w-full min-w-1200 flex flex-col justify-end mt-12' style={{ height: '110px' }}>
    <div className='w-full' style={{ height: '68px', boxShadow: '0px 2px 15px -7px #000000' }}>
      <div className='w-1200 h-full m-auto flex flex-row justify-between items-center'>
        <div className='h-full flex flex-row items-center'>
          <div className='relative' style={{ width: '68px', height: '68px' }}>
            <img className='absolute' src={ayou} alt='' style={{ width: '127px', height: '119px', maxWidth: '127px', left: 'calc(50% - 63px)', bottom: '-12px' }} />
          </div>
          <div className='text-xl font-bold ml-4'>申请免费试听</div>
          <div className='text-base ml-4 text-gray-500 '>用脑科学塑造终身成长的大脑品质</div>
        </div>

        <div className='h-12 bg-gray-300 rounded-full flex flex-row justify-between items-center' style={{ width: '500px' }}>
          <input className='h-full bg-transparent outline-none text-base pl-4' style={{ width: '250px' }} placeholder='请输入手机号码' />
          <SysBtn btnText='免费试听' btnType='auditionBannerBtn' clickCallback={onClickAudition} />
        </div>
      </div>
    </div>
  </div>
}

export default SysAuditionBanner