import React, { useEffect, useState } from 'react'
import { Form, Input, Select, Upload, Button, Modal, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import ReactQuill from 'react-quill'
import { debounce } from 'lodash'
import { reqArticleCreate, reqArticleUpdate } from '../../util/api'
import useBearStore from "../../zustand/state"

import 'react-quill/dist/quill.snow.css'
import './index.css'

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 19,
  },
}

const customModules = {
  // 方式1: 可以是简单的一维数组配置
  // toolbar: ["bold", "italic", "underline", "strike", "blockquote"],
  // 方式2: 可以配置二维数组，进行多个选项的配置
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    // 或者针对某一个配置项的key值，进行配置
    [{ header: [1, 2, false] }],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }]
  ],
  // 方式3: 可以自己指定工具栏的容器
  // toolbar: "#rq-toolbar"
}

// 新闻类型 选项
const options = [
  { value: 1, label: '公司新闻' },
  { value: 2, label: '家长课堂' },
  { value: 3, label: '科学用脑' },
]

/**
 * 新闻工具
 */
const NewsToolCreateEdit = (props) => {

  const { newsCreateEditDisplay, setNewsCreateEditDisplay } = useBearStore()
  // 
  const isCreate = props.operateType === 'create' ? true : false
  // 标题
  const modalTitle = isCreate ? '创建文章' : '编辑文章'
  // 表单实例
  const [form] = Form.useForm()
  // 内容
  const [content, setContent] = useState(isCreate ? '' : props.newsData.content)
  // 
  const formInitialValues = isCreate ? {} : props.newsData
  // 上传文件
  const [fileList, setFileList] = useState([]);
  // 上传设置
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  }

  useEffect(() => {

  }, [])

  /**
   * 设置 内容
   * @param {*} value 
   */
  const onChangeContent = debounce((value) => {
    setContent(value)
  }, 500)

  /**
   * 提交
   * @param {*} values 
   */
  const onFinish = (values) => {
    const formData = new FormData()
    for (let key in values) {
      formData.append(key, values[key])
    }
    formData.append('content', content)

    // formData.append('img', fileList[0])
    formData.append('img', fileList.length > 0 ? fileList[0] : '')

    // return
    if (isCreate) {
      createNews(formData)
    }
    else {
      updateNews(formData)
    }
  }

  /**
   * 创建新闻
   * @param {*} formData 
   */
  const createNews = (formData) => {
    reqArticleCreate(formData).then((res) => {
      if (res.res === 1) {
        setFileList([])
        setContent('')
        setNewsCreateEditDisplay(false)
        props.callback && props.callback()
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  /**
   * 编辑新闻
   */
  const updateNews = (formData) => {
    formData.append('id', props.newsData.id)
    reqArticleUpdate(formData).then((res) => {
      if (res.res === 1) {
        setFileList([])
        setContent('')
        setNewsCreateEditDisplay(false)
        props.callback && props.callback()
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  /**
   * 点击提交
   * @param {*} value 
   */
  const onOK = () => {
    form.submit()
  }

  /**
   * 取消
   */
  const onCancel = () => {
    setNewsCreateEditDisplay(false)
  }

  return <Modal
    title={modalTitle}
    centered
    open={newsCreateEditDisplay}
    onOk={onOK}
    onCancel={onCancel}
    maskClosable={false}
    destroyOnClose={true}
    width={1200}
    cancelText='取消'
    okText='确认'
  >
    <div className='w-full overflow-y-auto page_newscreateedit' style={{ height: '600px' }}>
      <Form
        {...layout}
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        preserve={false}
        initialValues={formInitialValues}
      >
        <Form.Item
          name="pass"
          label="秘　　钥"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          label="文章标题"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="show_in_home"
          label="是否添加到首页关键字"
        >
          <Select options={[
            { value: 0, label: '否' },
            { value: 1, label: '是' },
          ]} />
        </Form.Item>
        <Form.Item
          name="keywords"
          label="关键字"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description_search"
          label="描　　述"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="文章类型"
        >
          <Select allowClear options={options} />
        </Form.Item>
        <Form.Item
          label="宣传图片"
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>选择文件</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="description"
          label="列表内容"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="文章内容"
        >
          <div style={{ height: '550px' }}>
            <ReactQuill
              theme="snow"
              modules={customModules}
              value={content}
              onChange={onChangeContent}
              style={{ height: '500px', width: '100%' }}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  </Modal>
}

export default NewsToolCreateEdit